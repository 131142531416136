.top_select[data-v-0fc25e24] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.iconfont[data-v-0fc25e24] {
  margin-left: 4px;
  cursor: pointer;
}
.save[data-v-0fc25e24] {
  margin-top: 20px;
  text-align: right;
}
.icon-baocun[data-v-0fc25e24] {
  color: var(--themeColor, #17a2b8);
  font-size: 18px;
}
.icon-shanchu[data-v-0fc25e24] {
  color: #fe696a;
  font-size: 18px;
  margin-left: 5px;
}